import * as React from "react"
import { Link } from 'gatsby'
import Layout from "../../templates/layout-landing-page"
import Seo from "../../components/seo"
import "../../assets/css/screen.css"
import uvmLogo from '../../assets/img/welcome/ntm-lp/uvm-mvp-logo.svg';
// import uvmStars from '../../assets/img/welcome/ntm-lp/medicare-star-rating-rgb-uvm.svg';
// import uvmUSNewsVT from '../../assets/img/welcome/ntm-lp/badge-med-adv-2022-vt.svg';
// import uvmUSNewsNY from '../../assets/img/welcome/ntm-lp/badge-med-adv-2022-ny.svg';
import calendarsecondaryicn from "../../assets/img/calendar-secondary-icn.svg";
import seminarsecondaryicn from "../../assets/img/seminar-secondary-icn.svg";
import SideBarCard from '../../components/ui/SideBarCard';

const WelcomeNTMMember = ({ location }) => (

	<Layout location={location} heading1="" heading2="">

		<Seo title="Are you already a member?" meta={[{description: 'UVM Health Advantage is a new Medicare Advantage option, guided by doctors at the UVM Health Network and Medicare experts at MVP Health Care.'}]} bodyclass="ntm-lp existingmember" />
	
		<div className="topper">
			<div className="constrained">
				<div className="logo">
					<img src={uvmLogo} alt="UVM Health &amp; MVP Health Care" width="250" height="49" />
				</div>
			</div>
		</div>
		<header>
			<div className="constrained">
				<div className="content">
					<h1>Make a smooth transition to Medicare.</h1>
					<h2>We&rsquo;re thankful that you&rsquo;re a member of the MVP Health Care<sup>&reg;</sup> (MVP) family. In a few months, you&rsquo;ll be eligible to enroll in Medicare. You&rsquo;ll also have a new Medicare choice, guided by doctors at the UVM Health Network and Medicare experts at MVP.</h2>
				</div>
			</div>
		</header>

		<main>
			<div className="constrained">
				<h3>With UVM Health Advantage, you can get the care and support you need to take on your health goals and challenges to live your best life.</h3>
				<ul>
					<li>Medical and prescription drug coverage &ndash; plus dental, vision and hearing benefits &ndash; in one plan</li>
					<li>Access to an extensive regional Medicare network of providers and hospitals, including the UVM Health Network</li>
					<li>Virtual Care Services &ndash; through Gia<sup>&reg;</sup>, your 24/7 health care connection</li>
					<li>Expert Care Guides who work to ensure you get the right care and help you understand and take full advantage of your benefits</li>
					<li>Wellness programs and beneﬁts to help you stay ﬁt and reduce the likelihood of health challenges down the road</li>
				</ul>

				<div className="ctas secondary">
					<div className="flexwrap">
						<div className="secondary-cta">
							<img src={calendarsecondaryicn} alt="Schedule a personal consultation with an UVMHA Medicare Plan Guide" width="60" height="60" className="icon"/>
							<p className="head">
								<b>Talk with an expert Plan Guide.</b>
							</p>
							<p>Our UVM Health Advantage Plan Guides can personally help you understand your options, find the right plan and make sure your transition to your new plan goes smoothly with no disruption to your care.</p>

							<p>Search available phone, video or in-person appointments, or request a meeting at a time and place that&rsquo;s convenient for you.</p>
							
							<a href="https://outlook.office365.com/owa/calendar/UVMHealthAdvantagePlanConsultationScheduling@mvphealthcare.onmicrosoft.com/bookings/" className="button external_button" target="_blank" rel="noreferrer" >Search Appointments</a>
							<Link to="/meet-with-us/request-consultation/" className="button secondary">Request a Consultation</Link>
						</div>
						<div className="secondary-cta">
							<img src={seminarsecondaryicn} alt="Attend a Free Medicare Seminar" width="60" height="60" className="icon" />
							<p className="head">
								<b>Attend a free Medicare seminar.</b>
							</p>
							<p>
								Learn the basics of Medicare or explore the plans available to you in one of our live online seminars.
							</p>
							<Link to="/meet-with-us/attend-a-seminar/" className="button secondary">Find a Seminar</Link>
						</div>
					</div>
				</div>
				<p className="disclaimer">Per current safety guidelines, you must be fully vaccinated against COVID-19 and received a booster dose if eligible to attend in-person appointments at an MVP Health Care office. If you are not vaccinated, please select a video or phone appointment. Masks are not required in MVP offices, but you may choose to wear a mask if you prefer.</p>
			</div>
		</main>

		<aside>

			<div className="cardwrap">
				<div className="constrained">
					<div className="flexwrap">
						<SideBarCard type="basics" />
						<SideBarCard type="plans2" />
						<SideBarCard type="prt2" />
					</div>
				</div>
			</div>
			
			{/* <div className="awards">
				<div className="constrained">
					<ul>
						<li><img src={uvmStars} alt="4.5 Stars out of 5 Stars Overall by Medicare 2022" width="198" height="144" /></li>
						<li><img src={uvmUSNewsVT} alt="Best Insurance Companies - Medicare Advantage Vermont 2022" width="198" height="144" /></li>
						<li><img src={uvmUSNewsNY} alt="Best Insurance Companies - Medicare Advantage Vermont 2022" width="198" height="144" /></li>
					</ul>
				</div>
			</div> */}

		</aside>
	</Layout>
)

export default WelcomeNTMMember
